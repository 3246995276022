import React, { useEffect } from "react"
import aaConnector from "@netmanagement/aa-connector"

import Page from "~/models/page"
import patterns from "~/helpers/page-tag-patterns"

const analyticsConfig = {
  externalScript: process.env.GATSBY_AA_SCRIPT,
  fullFunnelQueryParamName: process.env.GATSBY_AA_FULL_FUNNEL_QUERY_PARAM,
  globalVariableName: "adobeTags",
  linkContainersArray: [],
  timeOnSiteCustomPings: [10, 30, 45],
  outboundLinkFolder: "/links/",
  pageTagLocalNames: [
    "GIN_language",
    "GIN_country",
    "GIN_main_category",
    "GIN_sub_categories",
    "GIN_vertical",
    "GIN_sub_vertical",
    "GIN_tri_vertical",
    "GIN_toplist",
    "GIN_primary_sub_category",
    "GIN_equivalent_page",
    'GIN_toplist_data_ids',
    'GIN_partner_data_ids',
    'GIN_variant',
    'GIN_page_state',
    'GIN_page_platform',
    'GIN_event',
    'GIN_site_section'
  ],
  transactionIdSiteNumber: process.env.GATSBY_AA_TRANSACTION_ID_SITE_NUMBER,
  userTagLocalNames: [
    "GIN_mobile",
    "GIN_tablet",
    "GIN_user_continent",
    "GIN_user_country",
    "GIN_user_state",
    "GIN_user_city",
  ],
  breadcrumbsSelector: "#breadcrumb > li",
  campaignParam: 'cmpgin',
  debug: process.env.GATSBY_ACTIVE_ENV !== "production",
}

const defaultPageTags = {
  GIN_language: "English",
  GIN_country: "India",
  GIN_main_category: "",
  GIN_sub_categories: "",
  GIN_vertical: "Casino",
  GIN_sub_vertical: "",
  GIN_tri_vertical: "",
  GIN_toplist: "",
  GIN_primary_sub_category: "",
  GIN_equivalent_page: "",
  GIN_toplist_data_ids: [],
  GIN_partner_data_ids: [],
  GIN_variant: "",
  GIN_page_state: "",
  GIN_page_platform: "",
  GIN_event: "",
  GIN_site_section: ""
}

type Props = {
  page?: Page
}

function Analytics({ page }: Props) {
  if (!window.connector) {
    window.connector = new aaConnector(analyticsConfig)
  }

  useEffect(() => {
    if (!process.env.GATSBY_AA_SCRIPT) return

    let pageTags = {}

    if (!page) {
      // 404 Not Found
      pageTags = {
        ...defaultPageTags,
        GIN_main_category: "Non-Commercial",
        GIN_sub_categories: ["Error 404"],
        GIN_toplist: false,
        GIN_primary_sub_category: "Error 404",
      }
    } else {
      pageTags = patterns
        .filter(pattern => {
          var p = new RegExp(pattern.pathnames.join("|"))
          return p.test(page.url.pathname)
        })
        .reduce(
          (tags: any, pattern: any) => {
            return {
              ...tags,
              ...pattern.tags,
            }
          },
          {
            ...defaultPageTags,
          }
        )
    }

    window.adobeTags = pageTags
    window.connector.emitPageView()
  }, [])

  return <div></div>
}

export default Analytics
