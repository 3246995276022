const patterns = [
  {
    pathnames: ["^/$"],
    tags: {
      GIN_main_category: "Commercial",
      GIN_sub_categories: ["Homepage", "Toplists"],
      GIN_toplist: true,
      GIN_primary_sub_category: "Homepage",
    },
  },
  {
    pathnames: ["^/casinos/[^]+$"],
    tags: {
      GIN_main_category: "Commercial",
      GIN_sub_categories: ["Reviews"],
      GIN_toplist: false,
      GIN_primary_sub_category: "Reviews",
    },
  },
  {
    pathnames: [
      "^/about-us/$",
      "^/about-us/[^]+$",
      "^/privacy-policy/$",
      "^/terms-of-service/$",
    ],
    tags: {
      GIN_main_category: "Non-Commercial",
      GIN_sub_categories: ["EAT"],
      GIN_toplist: false,
      GIN_primary_sub_category: "EAT",
    },
  },
  {
    pathnames: [
      "^/live-casinos/$",
      "^/casinos/$",
      "^/compare/$",
      "^/bonuses/$",
    ],
    tags: {
      GIN_main_category: "Commercial",
      GIN_sub_categories: ["Toplists"],
      GIN_toplist: true,
      GIN_primary_sub_category: "Toplists",
    },
  },
  {
    pathnames: ["^/games/[^]+$"],
    tags: {
      GIN_main_category: "Commercial",
      GIN_sub_categories: ["Games", "Toplists"],
      GIN_toplist: true,
      GIN_primary_sub_category: "Games",
    },
  },
  {
    pathnames: ["^/guides/[^]+$"],
    tags: {
      GIN_main_category: "Non-Commercial",
      GIN_sub_categories: ["Guides"],
      GIN_toplist: false,
      GIN_primary_sub_category: "Guides",
    },
  },
  {
    pathnames: ["/payments/$"],
    tags: {
      GIN_main_category: "Non-Commercial",
      GIN_sub_categories: ["Banking"],
      GIN_toplist: false,
      GIN_primary_sub_category: "Banking",
    },
  },
  {
    pathnames: ["/payments/[^]+$"],
    tags: {
      GIN_main_category: "Commercial",
      GIN_sub_categories: ["Banking", "Toplists"],
      GIN_toplist: true,
      GIN_primary_sub_category: "Banking",
    },
  },
  {
    pathnames: ["^/free-games/$"],
    tags: {
      GIN_main_category: "Commercial",
      GIN_sub_categories: ["Free Games", "Toplists"],
      GIN_toplist: true,
      GIN_primary_sub_category: "Free Games",
    },
  },
  {
    pathnames: ["^/articles/$", "^/articles/[^]+$"],
    tags: {
      GIN_main_category: "Non-Commercial",
      GIN_sub_categories: ["Articles"],
      GIN_toplist: false,
      GIN_primary_sub_category: "Articles",
    },
  },
  {
    pathnames: ["^/[^]+/teen-patti/$"],
    tags: {
      GIN_sub_vertical: "Teen Patti",
    },
  },
  {
    pathnames: ["^/[^]+/rummy/$"],
    tags: {
      GIN_sub_vertical: "Rummy",
    },
  },
]

export default patterns
